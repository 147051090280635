<template>
  <div class="info-flexible-combo">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row>
          <b-col cols="3">
            <b-form-group class="required-control">
              <label>Tên combo:</label>
              <b-form-input
                size="sm"
                v-model="mainModel.name"
                placeholder="Nhập tên"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="required-control">
              <label>Mã combo:</label>
              <b-form-input
                size="sm"
                v-model="mainModel.code"
                placeholder="Nhập mã"
              ></b-form-input>
            </b-form-group>
            <b-form-group class="required-control">
              <label>Thời gian:</label>
              <div class="d-flex justify-content-center align-items-center">
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="mainModel.startDate"
                ></date-picker>
                <span class="ml-1 mr"></span>
                <date-picker
                  placeholder="Đến ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="mainModel.endDate"
                ></date-picker>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group>
              <label>Mô tả:</label>
              <b-form-textarea
                class="input-style"
                v-model="mainModel.description"
                placeholder="Nhập mô tả"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group>
              <label
                >Cửa hàng áp dụng (để trống nếu áp dụng toàn hệ thống)</label
              >
              <b-input-group>
                <vue-multi-select
                  style="margin-bottom: 2rem"
                  v-model="mainModel.storeIds"
                  :options="stores"
                  placeholder="Chọn cửa hàng"
                  option-label="name"
                ></vue-multi-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <label>Trạng thái:</label>
              <b-form-select
                size="sm"
                class="select-style"
                v-model="mainModel.isActive"
                :options="[
                  {
                    id: 1,
                    name: 'Hoạt động',
                  },
                  {
                    id: 0,
                    name: 'Ngừng hoạt động',
                  },
                ]"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <hr
          class="hr-text"
          data-content="Cấu hình bộ combo"
          style="font-weight: 600"
        />
        <b-row>
          <b-form-group class="col-md-12 pr-0">
            <label>Tìm sản phẩm:</label>
            <b-col class="row pr-0">
              <b-col md="6" class="p-0">
                <Autosuggest
                  :model="searchProduct"
                  :suggestions="optionItems"
                  placeholder="tên, mã, mã vạch sản phẩm"
                  :limit="10"
                  @select="onSelectedItem"
                  @change="onInputChangeSearch"
                  :disabled="false"
                  suggestionName="name"
                />
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="
                    select-style
                    border-left-0
                    rounded-left-0 rounded-right-0
                  "
                  v-model="selectTypeSearch"
                  :options="[
                    {
                      id: 2,
                      name: 'Sản phẩm',
                    },
                    {
                      id: 3,
                      name: 'Danh mục sản phẩm',
                    },
                    {
                      id: 4,
                      name: 'Thương hiệu',
                    },
                  ]"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  @change="debounceInputSearch"
                ></b-form-select>
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="select-style border-left-0 rounded-left-0"
                  v-model="selectTypeOfProduct"
                  :options="itemTypeOptions"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  @change="debounceInputSearch"
                ></b-form-select>
              </b-col>
            </b-col>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col>
            <b-table bordered hover :fields="childFields" :items="listItem">
              <template v-slot:cell(type)="row">
                <span v-if="row.item.type === 1">Sản phẩm</span>
                <span v-if="row.item.type === 2">Danh mục</span>
                <span v-if="row.item.type === 3">Thương hiệu</span>
              </template>
              <template v-slot:cell(discountRate)="row">
                <b-row>
                  <b-col class="pr-0" cols="5">
                    <b-form-select
                      class="select-style"
                      v-model="row.item.discountType"
                      :options="listAmountType"
                      size="sm"
                      value-field="id"
                      text-field="name"
                    >
                    </b-form-select>
                  </b-col>
                  <b-col class="pl-0" cols="7">
                    <b-form-input
                      size="sm"
                      v-model="row.item.discountRate"
                      placeholder="Nhập tỉ lệ ck"
                      class="text-right"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:cell(quantity)="row">
                <b-form-input
                  size="sm"
                  v-model="row.item.quantity"
                  placeholder="Nhập số lượng"
                  class="text-right"
                ></b-form-input>
              </template>
              <template v-slot:cell(description)="row">
                <b-form-textarea
                  size="sm"
                  v-model="row.item.description"
                  placeholder="Nhập mô tả"
                  :rows="3"
                  :max-length="255"
                />
              </template>
              <template v-slot:cell(actions)="row">
                <v-icon
                  small
                  class="text-danger"
                  @click="showDeleteProductAlert(row.item.id, 'product')"
                  v-b-tooltip
                  title="Xóa"
                  >mdi-delete</v-icon
                >
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="my-5"></div>
        <hr
          class="hr-text"
          data-content="Cấu hình cho sản phẩm"
          style="font-weight: 600"
        />

        <b-row>
          <b-col md="4">
            <b-form-group label="Danh mục">
              <treeselect
                :options="categories"
                v-model="selectedCategory"
                :multiple="true"
                :normalizer="normalizer"
                :backspace-removes="false"
                @deselect="showDeleteCategoryAlert"
                placeholder="Chọn danh mục"
                :max-height="200"
                noResultsText="Không tìm thấy kết quả"
                valueFormat="object"
                :clearable="false"
              ></treeselect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-form-group class="col-md-12 pr-0">
            <label>Tìm sản phẩm:</label>
            <b-col class="row pr-0">
              <b-col md="6" class="p-0">
                <Autosuggest
                  :model="searchProductFor"
                  :suggestions="optionProducts"
                  placeholder="tên, mã, mã vạch sản phẩm"
                  :limit="10"
                  @select="onSelectedProductFor"
                  @change="onInputChangeProductFor"
                  :disabled="false"
                  suggestionName="productName"
                />
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="
                    select-style
                    border-left-0
                    rounded-left-0 rounded-right-0
                  "
                  v-model="selectTypeSearchFor"
                  :options="[
                    {
                      id: 1,
                      name: 'Sản phẩm IMEI',
                    },
                    {
                      id: 2,
                      name: 'Sản phẩm',
                    },
                  ]"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="select-style border-left-0 rounded-left-0"
                  v-model="selectTypeOfProductFor"
                  :options="productTypes"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
              <b-col md="2" class="pr-0" v-if="false">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-secondary font-weight-bolder btn-sm"
                    @click="showModalImportProductFor"
                  >
                    <i style="font-size: 1rem" class="far fa-file-excel"></i>
                    Nhập từ excel
                  </button>
                </div>
              </b-col>
            </b-col>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col md="10">
            <div>
              <b-table
                bordered
                hover
                class="col-md-12"
                :fields="productfields"
                :items="products"
                :per-page="10"
                :current-page="currentPageProductTable"
              >
                <template v-slot:cell(actions)="row">
                  <v-icon
                    small
                    class="text-danger"
                    @click="showDeleteProductAlert(row.item.id, 'productFor')"
                    v-b-tooltip
                    title="Xóa"
                    >mdi-delete</v-icon
                  >
                </template>
              </b-table>
              <b-pagination
                v-show="products.length > 10"
                v-model="currentPageProductTable"
                :total-rows="products.length"
                :per-page="10"
                align="right"
              ></b-pagination>
            </div>
          </b-col>
          <b-col cols="6" md="4"></b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          v-bind:style="btnCreate"
          size="sm"
          variant="primary"
          class="font-weight-bolder mr-4"
          @click="onSubmit"
        >
          Lưu
        </b-button>
        <b-button
          v-bind:style="btnCreate"
          size="sm"
          variant="secondary"
          class="font-weight-bolder"
          @click="onBack"
        >
          Hủy
        </b-button>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.input-style {
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
.muti-basic-custom .rounded {
  border-radius: 0.28rem !important;
}
.multi-select-wrapper {
  flex: 1 1 auto;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { getToastInstance } from '@/utils/toastHelper';
import { cloneDeep } from 'lodash';

import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_PROMOTION,
  URL_IMPORT_PRODUCT_FOR_PROMOTION,
} from '@/utils/constants';
import {
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
  convertPrice,
  unMaskPrice,
} from '@/utils/common';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import VueMultiSelect from 'vue-simple-multi-select';
import { v4 } from 'uuid';
import _ from 'lodash';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { DP_CONFIG } from '@/utils/date';
import { AMOUNT_TYPE } from '@/utils/enum';

const SEARCH_TYPE = {
  PRODUCT: 2,
  CATEGORY: 3,
  BRAND: 4,
};

export default {
  mixins: [validationMixin],
  data() {
    return {
      title: '',
      dpConfigs: DP_CONFIG,
      selectedCategory: [],
      childFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'type',
          label: 'Loại',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-left',
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
          tdClass: 'text-left',
        },
        {
          key: 'discountRate',
          label: 'CK',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          thStyle: { fontWeight: 600, color: '#181c32', width: '7%' },
          tdClass: 'text-right',
        },
        {
          key: 'description',
          label: 'Mô tả',
          thStyle: { fontWeight: 600, color: '#181c32', width: '28%' },
          tdClass: 'text-left',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      mainModel: {
        id: null,
        code: '',
        name: '',
        description: '',
        startDate: new Date(),
        endDate: null,
        products: [],
        appliedCategories: [],
        appliedProducts: [],
        isActive: 1,
        storeIds: [],
        discountType: AMOUNT_TYPE.MONEY,
        discountAmount: 0,
        isLimit: false,
        limit: 0,
      },
      productfields: [
        {
          key: 'barCode',
          label: 'Mã vạch',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productCode',
          label: 'Mã',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Tên',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      filteredProducts: [],
      products: [],
      searchProductFor: '',
      categories: [],
      isSearching: false,
      productTypes: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeSearchFor: 1,
      selectTypeOfProductFor: 0,
      importItems: [],
      file: null,
      excellist: [],
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
      ],
      importItemFor: [],
      fileFor: null,
      excellistFor: [],
      currentPageFor: 1,
      fieldImportFor: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
      ],
      urlExcel: URL_IMPORT_PRODUCT_PROMOTION,
      urlExcelFor: URL_IMPORT_PRODUCT_FOR_PROMOTION,
      currentPageProductTable: 1,
      mask: currencyMask,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      isBusy: false,
      stores: [],
      listItem: [],
      searchProduct: '',
      filteredItems: [],
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      selectTypeSearch: 2,
      selectTypeOfProduct: 0,
      listAmountType: [
        {
          id: 1,
          name: '$',
        },
        {
          id: 2,
          name: '%',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    datePicker,
    Treeselect,
    VueMultiSelect,
    Autosuggest,
  },
  watch: {},
  computed: {
    optionProducts() {
      return [...cloneDeep(this.filteredProducts)];
    },
    optionItems() {
      return [...cloneDeep(this.filteredItems)];
    },
    itemTypeOptions() {
      if (this.selectTypeSearch === SEARCH_TYPE.PRODUCT) {
        return [
          {
            id: 0,
            name: 'Tất cả sản phẩm',
          },
          {
            id: 1,
            name: 'Sản phẩm cha',
          },
          {
            id: 2,
            name: 'Sản phẩm con',
          },
          {
            id: 3,
            name: 'Sản phẩm độc lập',
          },
          {
            id: 4,
            name: 'Sản phẩm cha + độc lập',
          },
          {
            id: 5,
            name: 'Sản phẩm con + độc lập',
          },
        ];
      } else if (this.selectTypeSearch === SEARCH_TYPE.CATEGORY) {
        return [
          {
            id: 0,
            name: 'Tất cả nhóm sản phẩm',
          },
          {
            id: 1,
            name: 'Nhóm sản phẩm cha',
          },
          {
            id: 2,
            name: 'Nhóm sản phẩm con',
          },
        ];
      } else {
        return [
          {
            id: 0,
            name: 'Tất cả thương hiệu',
          },
        ];
      }
    },
  },
  mounted() {
    this.title = 'Thêm mới bộ sản phẩm combo';
    if (this.$route.query.id) {
      this.title = 'Cập nhật bộ sản phẩm combo';
      this.mainModel.id = this.$route.query.id;
      this.getInfoById();
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.getCategories();
    this.fetchStore();
  },
  methods: {
    ...getToastInstance(),
    convertPrice,
    getCategories() {
      ApiService.query('category')
        .then(({ data }) => {
          if (!data.status) {
            return makeToastFaile('Lấy dữ liệu danh mục thất bại!');
          }
          this.categories = data.data.list_caterogy;
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    showDeleteCategoryAlert: function (item) {
      if (!item.exist) {
        return;
      }
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: `Bạn có chắc muốn xóa ${item.name} này không ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then(async (result) => {
        if (result.dismiss != 'cancel') {
          const index = this.categories.findIndex(
            (category) => category.id === item.id,
          );
          if (index > -1) {
            this.categories[index].exist = false;
          }
        } else {
          this.selectedCategory.push(item);
        }
      });
    },
    showDeleteProductAlert: function (id, type) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: `Bạn có chắc muốn xóa sản phẩm này không ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then(async (result) => {
        if (result.dismiss != 'cancel') {
          if (type === 'product') {
            this.listItem = _.reject(this.listItem, (item) => {
              return item.id == id;
            });
            this.listItem.forEach((element, index) => {
              element.count = index + 1;
            });
          } else if (type === 'productFor') {
            this.products = _.reject(this.products, (item) => {
              return item.id == id;
            });
          }
        }
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.filteredProducts = [];
      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        typeSearch: this.selectTypeSearchFor,
        typeOfProduct: this.selectTypeOfProductFor,
      };
      ApiService.query(`productSearch/search-by-type`, {
        params,
      }).then((response) => {
        this.filteredProducts = response.data.data;
        this.isSearching = false;
      });
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      const index = this.products.findIndex(
        (product) => product.productId === option.item.productId,
      );
      if (index > -1) {
        return makeToastFaile(`${option.item.productName} đã tồn tại`);
      }
      this.products.push({
        id: v4(),
        productId: option.item.productId,
        barCode: option.item.barCode,
        productName: option.item.productName,
        productCode: option.item.productCode,
      });
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;
      this.debounceInputFor();
    },
    onInputChangeSearch(text) {
      this.searchProduct = text;
      this.debounceInputSearch();
    },
    onBack: function () {
      history.back();
    },
    initPayload() {
      const params = cloneDeep(this.mainModel);
      params.storeIds = _.map(params.storeIds, 'id').join();

      const items = this.listItem.map((item) => {
        return {
          id: item.id,
          type: item.type,
          referenceId: item.referenceId,
          discountRate: item.discountRate,
          discountType: item.discountType,
          quantity: item.quantity,
          description: item.description,
        };
      });

      return {
        id: params.id,
        name: params.name,
        code: params.code,
        storeIds: params.storeIds ? params.storeIds : '-1',
        description: params.description,
        isActive: params.isActive,
        startDate: params.startDate
          ? moment(params.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        endDate: params.endDate
          ? moment(params.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        items: items,
        products: this.products,
        categories: this.selectedCategory,
        isLimit: params.isLimit,
        limit: params.limit ? unMaskPrice(params.limit) : 0,
        discountType: params.discountType,
        discountAmount: params.discountAmount
          ? unMaskPrice(params.discountAmount)
          : 0,
      };
    },
    onSubmit: async function () {
      this.isBusy = true;

      if (!this.mainModel.name || !this.mainModel.name.trim()) {
        makeToastFaile('Vui lòng nhập tên bộ combo!');
        return;
      }

      if (!this.mainModel.code || !this.mainModel.code.trim()) {
        makeToastFaile('Vui lòng nhập mã bộ combo!');
        return;
      }

      if (!this.mainModel.startDate || !this.mainModel.endDate) {
        makeToastFaile('Vui lòng nhập thời gian cho bộ combo!');
        return;
      }

      if (!this.listItem || !this.listItem.length) {
        makeToastFaile('Vui lòng nhập sản phẩm!');
        return;
      }

      const payload = this.initPayload();
      if (!payload.id) {
        this.onCreate(payload);
      } else {
        this.onUpdate(payload);
      }
    },
    onCreate(payload) {
      ApiService.post('flexible-combo', payload)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    onUpdate(payload) {
      ApiService.put('flexible-combo', payload)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    debounceInputFor: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    showModalImportProductFor() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-for-modal'].show();
    },
    fetchStore: async function () {
      this.stores = [];
      ApiService.get(`stores/getStores`).then((response) => {
        const listStore = response.data.data;
        this.stores = listStore.map((element) => {
          return {
            id: element.id,
            name: element.name,
          };
        });
      });
    },
    debounceInputSearch: decounce(function () {
      if (!this.isSearching) {
        const textSearch = this.searchProduct
          ? this.searchProduct.trim()
          : null;
        if (!textSearch) {
          return;
        }
        if (this.selectTypeSearch === SEARCH_TYPE.BRAND) {
          this.searchBrand(textSearch);
        } else {
          this.searchItem(textSearch);
        }
      }
    }, TIME_TRIGGER),
    onSelectedItem(option) {
      this.searchProduct = '';
      const index = this.listItem.findIndex(
        (item) => item.referenceId === option.item.id,
      );
      if (index > -1) {
        return makeToastFaile(`${option.item.name} đã tồn tại`);
      }
      this.listItem.push({
        id: v4(),
        type: this.mapReferenceTypeWithSearch(this.selectTypeSearch),
        referenceId: option.item.id,
        name: option.item.name,
        description: '',
        discountRate: 0,
        discountType: AMOUNT_TYPE.MONEY,
        quantity: 1,
      });
      this.listItem.map((item, index) => {
        item.count = index + 1;
      });
    },
    searchItem(textSearch) {
      this.isSearching = true;
      this.filteredItems = [];
      const params = {
        searchProduct: textSearch,
        typeSearch: this.selectTypeSearch,
        typeOfProduct: this.selectTypeOfProduct,
      };
      ApiService.query(`productSearch/search-by-product-category`, {
        params,
      }).then((response) => {
        this.filteredItems = response.data.data;
        this.isSearching = false;
      });
    },
    getInfoById() {
      ApiService.get(`flexible-combo/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel.isActive = data.data.isActive;
          this.mainModel.code = data.data.code ? data.data.code : '';
          this.mainModel.name = data.data.name ? data.data.name : '';
          this.mainModel.description = data.data.description
            ? data.data.description
            : '';
          this.mainModel.storeIds = data.data.listStore;
          this.mainModel.discountAmount = data.data.discountAmount
            ? data.data.discountAmount
            : 0;
          this.mainModel.discountType = data.data.discountType
            ? data.data.discountType
            : AMOUNT_TYPE.MONEY;
          this.mainModel.startDate = data.data.startDate
            ? moment(data.data.startDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.endDate = data.data.endDate
            ? moment(data.data.endDate).format('DD-MM-YYYY')
            : '';
          this.listItem = data.data.listItem.map((item, index) => {
            return {
              ...item,
              count: index + 1,
            };
          });
          this.selectedCategory = data.data.appliedCategories;
          this.products = data.data.appliedProducts;
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    calSumDiscountRate() {
      this.listItem.forEach((x) => (x.discountRate = parseInt(x.discountRate)));
      return _.sumBy(this.listItem, 'discountRate');
    },
    searchBrand: function (textSearch) {
      this.listBrand = [];
      const param = {
        page: 1,
        pageSize: 10,
        name: textSearch,
      };
      const paramQuery = {
        params: {
          param,
        },
      };
      ApiService.query('brands', paramQuery).then((response) => {
        this.filteredItems = response.data.brands;
      });
    },
    mapReferenceTypeWithSearch(searchType) {
      switch (searchType) {
        case SEARCH_TYPE.PRODUCT:
          return 1;
        case SEARCH_TYPE.CATEGORY:
          return 2;
        case SEARCH_TYPE.BRAND:
          return 3;
      }
    },
  },
};
</script>
